.form-control-1 {
  font-size: 16px;
  height: 35px;
  width: 50%;
  margin-left: 20px;
}
select.form-control:not([size]):not([multiple]) {
  height: 35px;
}
.form-group-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group > label {
  font-size: 16px;
}
.form-control {
  font-size: 16px;
}

.img-re {
  width: 100%;
  object-fit: cover;
}
.form-check {
  font-size: 16px;
  display: flex;
}
.form-check > input {
  margin-right: 50px;
}
.img-update {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin: 10px;
}
.img-update-news {
  width: 100%;
  object-fit: cover;
  margin: 10px;
}
.image-review {
  border: #3333 solid 2px;
  padding: 10px;
  border-radius: 7px;
  margin: 15px;
}
.img-check {
  position: relative;
}
.delete-img {
  position: absolute;
  top: 0;
  right: 0;
}
th {
  background-color: rgb(200, 200, 200);
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: white;
}
.auto-scroll {
  max-height: 150px;
  overflow: auto;
}
.img-news-admin{
  width: 200px;
  height: 150px;
  object-fit: cover;
}