body,
html {
  /* font-family: Roboto, sans-serif;
} */
font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
article {
  padding: 40px 0;
}
b,
strong {
  font-weight: 700;
}
p {
  font-size: 16px;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 500;
  line-height: 1.1;
}