.img-logo-navbar {
  height: 83px;
}
/* .nav-links {
  padding: 10px 8px;
}
.text {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
  font-weight: 600;
}
.text:hover {
  text-transform: uppercase;
  color: rgb(255, 235, 51);
  font-size: 20px;
  font-weight: 600;
  margin: 0 5px;
}
.text-active {
  text-transform: uppercase;
  color: rgb(255, 235, 51);
  font-size: 20px;
  font-weight: 600;
  margin: 0 5px;
} */

.container-header {
  margin: 0px 40px;
  display: flex;
}
.navbar {
  width: 100vw;
}
.bg-myColor-2 {
  background-color: rgba(51, 51, 51, 0.598);
}
.bg-myColor-1 {
  background-color: #80aa41ee;
}
.navbar-collapse {
  width: 100vw;
  max-height: 1000px !important;
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  border-top: 1px solid transparent;

  -webkit-overflow-scrolling: touch;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255,1)' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  border-width: 2px;
}

@media screen and (max-width: 760px) {

  .navbar-nav {
    margin: 0px 20px;
  }

  .navbar-collapse {
    width: 100vw;
    max-height: 1000px !important ;
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    -webkit-overflow-scrolling: touch;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-right: 50px;
  } 
}
