.content-home {
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

.carousel-inner .item > img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}
.carousel-indicators {
  display: none;
}
/* home service */
.home-service {
  padding: 40px 0;
}
.home-title {
  border-left: 5px solid #9acc2a;
  padding-left: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.font-title-home {
  font-size: 27px;
  color: #124a2f;
  text-transform: uppercase;
  font-weight: bold;
}

.title-inline {
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #333333;
  font-weight: bold;
}
.txt-box-service {
  font-size: 15px;
  text-align: justify;
  line-height: 1.8;
  padding-top: 5px;
  margin: 0 0 10px;
}
.btn-outline-success {
  /* height: 40px; */
  width: 110px;
  font-size: 14px;
  border: 3px solid #3e7319;
  color: #3e7319;
  text-transform: uppercase;
  border-color: #124a2f;
}
.btn-outline-secondary {
  /* height: 40px; */
  width: 110px;
  font-size: 14px;
  border: 3px solid #252525;
  color: #252525;
  text-transform: uppercase;
  border-color: #252525;
}
.btn-outline-success:hover {
  /* height: 40px; */
  width: 110px;
  font-size: 14px;
  border: 3px solid #3e7319;
  text-transform: uppercase;
  background-color: #3e7319;
}
.btn-outline-success:active {
  /* height: 40px; */
  width: 110px;
  font-size: 14px;
  border: 3px solid #3e7319;
  text-transform: uppercase;
}
.box-about {
  border: 7px solid #3e7319;

  padding-top: 29em;
}
.img-about {
  position: absolute;
  top: -6em;
  right: -6em;
  width: 350px;
}
.txt-box-about {
  padding: 4em 4em;
}
.content-box-about {
  font-size: 15px;
  margin-top: 20px;
}
.home-news {
  background: url("../../images//images/bg-3.jpg");
  background-size: cover;
  padding: 60px 20px 0 0;
}
.box-news {
  position: relative;
  background: #fff;
  border-radius: 5px;
}
.div-news {
  width: 100%;
  height: 200px;
  background: url("../../images//images/news-1.jpg") no-repeat;
  background-size: cover;
}
.ed-comments-cal {
  position: absolute;
  top: 0;
  right: 0;
}
.ed-comment {
  background: #6bc030;
  text-align: center;
  color: #fff;
  padding: 15px;
}
.ed-calendar {
  background: #1a3b05;
  text-align: center;
  color: #fff;
  padding: 15px;
}
/*  */
.ed-comments-cal-right {
  position: absolute;
  top: -5px;
  right: -30px;
}
.ed-comment-right {
  background: #6bc030;
  text-align: center;
  color: #fff;
  padding: 10px;
}
.ed-calendar-right {
  background: #1a3b05;
  text-align: center;
  color: #fff;
  padding: 10px;
}
.icon-news-home-right {
  font-size: 27.5px;
}
/*  */

.icon-news-home {
  font-size: 25px;
}
.txt-news {
  padding: 10px 20px;
}

.box-news-right {
  background: #fff;
  position: relative;
  transition: all 0.5s;
  display: flex;
  margin-bottom: 27px;
  height: 109px;
}
.box-news-right-1 {
  background: #fff;
  position: relative;
  transition: all 0.5s;
  display: flex;
  margin-bottom: 27px;
}
.box-news-right:hover {
  background: #3c840f;
  position: relative;
  transition: all 0.5s;
  display: flex;
  margin-bottom: 27px;
  color: #fff;
}
.box-news-right:hover > h2 {
  position: relative;
  transition: all 0.5s;
  display: flex;
  margin-bottom: 27px;
  color: #fff;
}
.img-news {
  display: inline-block;
  margin-right: 10px;
  width: 153px;
  height: 100%;
  object-fit: cover;
}
.carousel-control-next {
  display: none;
}
.carousel-control-prev {
  display: none;
}
.home-capacity {
  background: linear-gradient(
        141deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 50%,
        rgba(0, 0, 0, 0.6) 75%
      )
      right center / cover,
    url("../../images//images//capacity.jpg") no-repeat;
  padding: 0px;
  visibility: visible;
}
.ed-capacity {
  padding: 30px 5em 20px 15em;
  background: rgba(27, 62, 7, 0.7);
  color: #fff;
}

.title-capacity {
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.5;
  font-size: 25px;
}
.txt-capacity {
  display: flex;
  margin-top: 20px;
}
.info-capacity {
  padding: 20px 0;
}
.font-txt-capacity {
  font-size: 27px;
  margin: 0;
  margin-left: 20px;
}
.customer-review {
  padding: 40px 0;
}
.home-project {
  padding: 40px 0;
  background-color: #f7f7f7;
}
.home-contact {
  background-color: #fff;
  padding: 40px 0;
}
.img-customer {
  display: block;
  max-width: 100%;
  height: 250px;
  object-fit: cover;
}
.info-customer {
  padding: 20px 0;
  margin-left: 40px;
}
.icon-customer {
  color: #2b7000;
  font-size: 35px;
  margin-left: 5px;
}
.content-review {
  font-size: 16px;
}
.name-customer {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 10px;
}
.job-customer {
  font-size: 14px;
  color: #96bf63;
}
.home-share {
  background: linear-gradient(
        141deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 50%,
        rgba(0, 0, 0, 0.6) 75%
      )
      0% 0% / cover,
    url("../../images//images/share.jpg") no-repeat;
  visibility: visible;
}
.txt-share {
  color: #fff;
  padding: 4em 7em;
  text-align: center;
}
.font-title-share {
  font-size: 35px;
  font-weight: bold;
}
.font-content-share {
  margin: 40px 0 70px;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.register {
  display: flex;
  justify-content: center;
}
.hidden-xs {
  font-size: 15px;
  font-weight: normal;
  margin-right: 10px;
}
.txt-email-customer {
  width: 40vh;
  height: 35px;
  font-size: 14px;
}
.btn-address-customer {
  height: 35px;
  width: 80px;
  margin-top: 15px;
  background-color: #2b7000;
  border-color: #124a2f;
  font-size: 14px;
}
.ed-product {
  margin-bottom: 20px;
  margin-top: 20px;
}
.setting-contact {
  background: linear-gradient(
      141deg,
      rgba(0, 0, 0, 0.44) 0%,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0.53) 75%
    ),
    url("../../images//images/banner-contact.jpg") no-repeat;
  padding: 40px;
  background-size: cover;
}
.txt-input-contact {
  height: 40px;
  width: 225px;
  font-size: 14px;
  margin-bottom: 20px;
}
.aera-txt-home {
  height: 160px;
  width: 225px;
  font-size: 14px;
  
}
.txt-news-hom {
  color: #6f6f6f;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 100px;
  text-align: justify;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 25px;
  background: rgb(0, 111, 9);
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}

.back-to-top:hover {
  background: rgb(0, 75, 6);
}

@media screen and (max-width: 760px) {
  .ed-comments-cal-right {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  .box-about {
    border: 7px solid #3e7319;
    padding-top: 35em;
    position: none;
    margin-top: 20px;
  }
  .img-about {
    position: none;
    top: 0em;
    right: 0em;
    width: 100%;
  }
  .ed-capacity {
    padding: 30px;
    background: rgba(27, 62, 7, 0.7);
    color: #fff;
  }
  .font-txt-capacity {
    font-size: 22px;
    margin: 0;
    margin-left: 20px;
  }
  .home-share {
    padding: 0;
    background: linear-gradient(
          141deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0.8) 50%,
          rgba(0, 0, 0, 0.6) 75%
        )
        0% 0% / cover,
      url("../../images//images/share.jpg") no-repeat;
    visibility: visible;
  }
  .txt-input-contact {
    height: 40px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .aera-txt-home {
    width: 100%;
    font-size: 14px;
  }
  .font-title-home {
    font-size: 23px;
    color: #124a2f;
    text-transform: uppercase;
    font-weight: bold;
  }
  .title-capacity {
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.5;
    font-size: 22px;
  }
  .font-title-share {
    font-size: 22px;
    font-weight: bold;
  }
}
