header {
  width: 100vw;
  position: absolute;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 80px; */
  padding: 0 15rem;
  background-color: rgba(51, 51, 51, 0.683);
  /* color: #eee; */
  text-decoration: none;
}

.text {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  margin: 0 6px;
  font-weight: 600;
  text-decoration: none;
}
.text:hover {
  text-transform: uppercase;
  color: rgb(255, 235, 51);
  font-size: 20px;
  font-weight: 600;
  margin: 0 6px;
  text-decoration: none;
}
.text-active {
  text-transform: uppercase;
  color: rgb(255, 235, 51);
  font-size: 20px;
  font-weight: 600;
  margin: 0 6px;
  text-decoration: none;
}
nav a {
  margin: 0 2rem;
  /* color: #eee; */
  text-decoration: none;
}
nav a:hover {
  text-transform: uppercase;
  color: rgb(255, 235, 51);
  font-size: 20px;
  margin: 0 6px;
  font-weight: 600;
  text-decoration: none;
}
header > nav > a {
  text-decoration: none;
  color: #fff;
}
header > nav > a:link {
  text-decoration: none;

}

header > nav > a:visited {
  text-decoration: none;

}

header > nav > a:hover {
  text-decoration: none;
}

header > nav > a:active {
  text-decoration: none;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  /* color: #eee; */
  visibility: hidden;
  opacity: 0;
  font-size: 3rem;
  margin-right: 5px;
}
@media only screen and (max-width: 1024px) {
  header {
    padding: 0 3rem;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    color: #eee;
  }
  header nav {
    /* position: fixed; */
    position: absolute;
    z-index: 2000;
    left: 0;
    top: 83px;
    padding-top: 10px;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgba(51, 51, 51, 0.683);
    transition: 0.8s;
    transform: translateY(-100vh);
  }
  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    font-size: 30px;
  }
  nav a {
    font-size: 1.5rem;
  }
}
