.title-news-details {
  margin: 30px 0;
}
.title-news-details > h2 {
  font-size: 30px;
  text-transform: uppercase;
}
.title-news-details > h5 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}
.title-news-details > h6 {
  border-bottom: 2px solid #6bc030;
  width: 200px;
  margin: 10px 0;
}
.img-new-details-thumb {
  width: 100%;
  height: 516px;
  object-fit: cover;
}
.title-right-news-dt {
  padding-top: 10px;
}
.title-right-news-dt > h2 {
  font-size: 28px;
  margin-bottom: 20px;
}
.title-right-news-dt > h5 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
@media screen and (max-width: 760px) {
  .mrgin-10 {
    margin-top: 10px;
  }
}
