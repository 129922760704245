.content-project-1 {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  padding: 20px;
  color: #fff;
  text-align: center;
  height: 400px;
}
.info-project {
  font-size: 15px;
}
.div-design2 {
  width: 100%;
  height: 190px;
  background: url("../../images//images/12.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}