.banner-news-page {
    background: linear-gradient(
        141deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.4) 75%
      ),
      url("../../images/images/slider-1559119998.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }