.limitt{
    color: #6f6f6f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin-right: 20px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 33px;
    text-align: left;
}
@media screen and (max-width: 760px) {
    .limitt{
        color: #6f6f6f;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        margin-right: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 40px;
        line-height: 20px;
        text-align: left;
    }
  }
  