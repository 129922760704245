.banner-about-page {
  background: linear-gradient(
      141deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.4) 75%
    ),
    url("../../images/images/slider-1559119998.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-about {
  color: #fff;
  text-align: center;
}
.title-about > h2 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 45px;
}
.about-page-1 {
  padding: 40px 0;
}
.txt-about {
  padding: 30px 60px 30px 0;
  text-align: justify;
}
.txt-about > p {
  text-align: justify;
  color: #666666;
  margin: 0 0 10px;
  font-size: 17px;
}
.bocviengoai {
  position: relative;
  margin-top: 30px;
}
.ed-border-picabout {
  position: absolute;
  border: 5px solid #3e7319;
  height: 445px;
  width: 445px;
  left: -30px;
  top: -30px;
}
.ed-picabout > img {
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
}
.ed-pic-about-page {
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 40px;
}
.pic-about-page {
  display: block;
  max-width: 100%;
  height: auto;
  margin:  5px 0;
}
.experience-page-1 {
  padding: 0px 0 30px;
}
.box-news-full {
  border-radius: 10px;
  margin-bottom: 40px;
}
.box-news-full > img {
  width: 100%;
  object-fit: cover;
  height: 270px;
}
.infor-news-full {
  margin-right: -15px;
  margin-left: -15px;
}
.title-post > h2 {
  color: #353535;
  font-size: 18px;
  margin: 20px 0;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.see-more {
  margin-top: 6px;
  width: 100%;
  transition-duration: 0.8s;
}
.see-more > a {
  text-decoration: none;
  font-size: 16px;
  color: #3e7319;
}
.see-more:hover {
  margin-top: 6px;
  width: 100%;
  padding-left: 50px;
}

.txt-news-full > p {
  font-size: 15px;
}
.limit-text-about {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.item-img-partnerr {
  /* min-height: 25rem;
  min-width: 20rem; */
  padding: 7px;
}
.item-img-partnerr > img {
  height: 220px;
  width: 220px;
  border-radius: 1rem;
  pointer-events: none;
  object-fit: cover;
}
.carousel-partnerr {
  cursor: grab;
  overflow: hidden;
}
.inner-carousel-partnerr {
  display: flex;
}
@media screen and (max-width: 760px) {
  .txt-about {
    padding: 30px;
    text-align: justify;
  }
  .bocviengoai {
    display: flex;
    justify-content: center;
    margin: 30px;
  }
  .ed-border-picabout {
    border: 5px solid #3e7319;
    height: 350px;
    width: 100%;
  }
  .ed-picabout > img {
    width: 100%;

    max-width: 100%;
    height: auto;
  }
  .title-about > h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .item-img-partnerr > img {
    height: 110px;
    width: 110px;
    border-radius: 1rem;
    pointer-events: none;
    object-fit: cover;
  }
}
