.banner-recruit-details-page {
  background: linear-gradient(
      141deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.4) 75%
    ),
    url("../../images/images/slider-1559119998.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-detail-design {
  margin: 30px 0;
}
.title-detail-design>h2 {
    font-size: 25px;
}
.title-detail-design>h6 {
    border-bottom: 2px solid #6bc030;
    width: 200px;
    margin: 10px 0;
}
