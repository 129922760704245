.banner-recruit-page {
  background: linear-gradient(
      141deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.4) 75%
    ),
    url("../../images/images/slider-1559119998.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ed-recuitment-1 {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}
.name-cruit {
  color: #3e7319;
  font-weight: 700;
  font-size: 17px;
}
.des-cruit {
    color: #3e7319;
    font-weight: 700;
    font-size: 16px;
  }
.des-cruit>span{
  color: #999;
  font-weight: 400;
}