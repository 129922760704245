.banner-service-page {
  background: linear-gradient(
      141deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.4) 75%
    ),
    url("../../images/images/slider-1559119998.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-page-1 {
  padding: 40px 0;
}
.img-service-1 > img {
  width: 100%;
  object-fit: cover;
  height: 57vh;

}
.content-service-box{
    margin: 40px 0;
}


